import React, { useEffect, useRef, useState } from "react"
import smoothscroll from 'smoothscroll-polyfill';
import Link from "../../../components/elements/Link";

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Price from "../../../components/elements/ProductItem/price"
import * as styles from "./styles.module.scss";
import AddToCartButton from "../../../components/elements/AddToCartButton"
import parse from 'html-react-parser';
import Accordion from "../../../components/elements/Accordion/Accordion"
import AccordionContainer from "../../../components/elements/Accordion"
import { useForm } from 'react-hook-form'
import Button from "../../../components/elements/Button"
import Counter from "../../../components/elements/Counter"
import Ratings from "../../../components/elements/Ratings"
import ImageCarousel from "../../../components/elements/ImageCarousel";
import getProductImageUrl from "../../../functions/image";
import TextInput from "../../../components/elements/Inputs/TextInput";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setNotification } from "../../../store/actions";
import { API_BASE, GRAPHQL_BASE } from "../../../spectory-config";
import LoadingSpinner from "../../../components/elements/LoadingSpinner";
import PromotionsSlider from "../../../components/elements/Slider/Promotions";
import ProductSlider from "../../../components/elements/Slider/Products";


// export const query = graphql`

// `

const SimpleProductPage = ({ serverData }) => {

    smoothscroll.polyfill();

    const { register, handleSubmit } = useForm();
    const commentRef = useRef();

    const product = serverData.product;

    let sameProductsList = [];

    serverData.relatedProducts?.forEach(product => {
        sameProductsList.push({
            id: product.databaseId,
            sku: product.sku,
            title: product.title,
            price: product.regularPrice,
            pricePromotion: product.salePrice,
            detail: '',
            uri: `/${product.destination}`,
        })
    })

    const [resettingRatingForm, setResettingRatingForm] = useState(false);
    const [rating, setRating] = useState(0);
    const [ratingError, setRatingError] = useState(false);
    const [loading, setLoading] = useState(false);


    const [stockStatus, setStockStatus] = useState(product.stockStatus);
    const [price, setPrice] = useState(product.price);
    const [regularPrice, setRegularPrice] = useState(product.regularPrice);
    const [salePrice, setSalePrice] = useState(product.salePrice);

    const [fallbackPrice, setFallbackPrice] = useState(product.price);
    const [fallbackRegularPrice, setFallbackRegularPrice] = useState(product.regularPrice);
    const [fallbackSalePrice, setFallbackSalePrice] = useState(product.salePrice);

    const [productId, setProductId] = useState(product.databaseId);
    const [productTitle, setProductTitle] = useState(product.title);
    const [productDetail, setProductDetail] = useState('');
    const [textfieldValue, setTextfieldValue] = useState('');
    const [productAdditionalData, setProductAdditionalData] = useState({});
    const [maxQuantity, setMaxQuantity] = useState(product.quantity.max || 1);
    const [sku, setSku] = useState(product.sku);
    const [cartImageUrl, setCartImageUrl] = useState('');

    const [productAttributes, setProductAttributes] = useState({});

    const [quantity, setQuantity] = useState(1);

    let quantityDiscounts = null;
    if (product.quantityDiscount !== undefined) {
        quantityDiscounts = product.quantityDiscount.discounts;
        // Resort
        quantityDiscounts.sort((a, b) => {
            if (a.minQuantity === b.minQuantity) {
                return 0;
            }
            else {
                return (a.minQuantity < b.minQuantity) ? -1 : 1;
            }
        });

    }



    const dispatch = useDispatch();

    useEffect(() => {

        console.log("SET PRODUCT", product);

        setPrice(product.price);
        setRegularPrice(product.regularPrice);
        setSalePrice(product.salePrice);

        setFallbackPrice(product.price);
        setFallbackRegularPrice(product.regularPrice);
        setFallbackSalePrice(product.salePrice);

        setStockStatus(product.stockStatus);
        setSku(product.sku);
        setCartImageUrl(getProductImageUrl(product.sku, '260x260'));
        setMaxQuantity(product.quantity.max);
        setProductTitle(product.title);
        setProductDetail(product.attributeSummary);
        setProductAttributes(product.attributes);
        setProductId(product.databaseId);
        setProductAdditionalData({});
        setTextfieldValue('');

        setQuantity(1);

        if (product.variations !== undefined) {
            product.variations.forEach(variation => {
                setProductId(variation.id);
            })
        }

    }, [serverData.product])

    useEffect(() => {

        if (product.variations === undefined) {
            return false;
        }

        product.variations.forEach(variation => {
            if (variation.id === productId) {
                setPrice(variation.price);
                setRegularPrice(variation.regularPrice);
                setSalePrice(variation.salePrice);

                setFallbackPrice(variation.price);
                setFallbackRegularPrice(variation.regularPrice);
                setFallbackSalePrice(variation.salePrice);

                setStockStatus(variation.stockStatus);
                setSku(variation.sku);
                setCartImageUrl(getProductImageUrl(variation.sku, '260x260'));
                setMaxQuantity(variation.quantity.max);
                // setProductTitle(variation.title);
                setProductDetail(variation.attributeSummary);
                setProductAttributes(variation.attributes);

                if (quantity > variation.quantity.max) {
                    setQuantity(variation.quantity.max)
                }
            }
        })

    }, [productId])

    // Check quantityDiscount
    useEffect(() => {
        if (quantityDiscounts === null || quantityDiscounts.length < 1) {
            return;
        }

        if (parseInt(quantity) < parseInt(quantityDiscounts[0].minQuantity)) {
            setPrice(fallbackPrice);
            setSalePrice(fallbackSalePrice);
            return;
        }

        quantityDiscounts.forEach((discount) => {
            if (parseInt(quantity) >= parseInt(discount.minQuantity)) {
                setPrice(discount.quantityPrice);
                setSalePrice(discount.quantityPrice);
            }
        })


    }, [quantity])


    // Check quantityDiscount
    useEffect(() => {
        setProductAdditionalData(prev => {
            prev['textField'] = textfieldValue;
            return prev;
        })
    }, [textfieldValue])



    const resetRatingForm = () => {
        setResettingRatingForm(true);
        setTimeout(() => setResettingRatingForm(false), 20);
    }

    const submitRating = (data) => {
        setRatingError(false)

        if (rating === 0) {
            setRatingError(true)
            return false;
        }

        if (data.email == '') return false;

        setLoading(true);

        let body = {
            email: data.email,
            first_name: data.firstname,
            last_name: data.lastname,
            rating: rating,
            product_id: product.databaseId,
        }

        axios.post(`${API_BASE}/shop/rating/add`, body)
            .then((response) => {

                setLoading(false);

                if (response.data.c == 200) {
                    dispatch(setNotification({
                        show: true,
                        title: "Bewertung erfolgreich hinzugefügt.",
                        type: "success"
                    }));

                    resetRatingForm();

                } else {
                    dispatch(setNotification({
                        show: true,
                        title: "Etwas ist schief gelaufen, bitte versuchen Sie es erneut.",
                        type: "error"
                    }))
                }
            })
            .catch(err => {
                setLoading(false);
                dispatch(setNotification({
                    show: true,
                    title: "Etwas ist schief gelaufen, bitte versuchen Sie es erneut.",
                    type: "error"
                }))

            })

    }

    const scrollToComments = () => {
        const container = document.getElementById('commentRef');

        window.scrollTo({
            top: container.offsetTop,
            left: 0,
            behavior: 'smooth'
        })

    }

    let totalRating = 0;
    let ratingsSum = 0;
    product.comments.forEach(comment => {
        ratingsSum = ratingsSum + parseInt(comment.rating);
    })

    totalRating = ratingsSum / product.comments.length;

    let categoryLinks = product.categories.map((category, key) => {
        return (<><Link to={category.uri}>{parse(category.name)}</Link>{key < product.categories.length - 1 ? ', ' : ''}</>);
    })

    const metaSection = <>
        Artikelnummer: {product.sku}<br />
        Kategorie:{` `}
        {
            categoryLinks
        }</>

    const translateStock = (stockStatus) => {
        switch (stockStatus) {
            case 'instock':
                return "vorrätig";

            case 'outofstock':
                return 'Nicht vorrätig';
        }
    }

    const stockSection = <div style={{ marginTop: 20 }} className={`${styles.stock} ${stockStatus === 'instock' ? styles.inStock : styles.lowStock}`}>{stockStatus !== null ? translateStock(stockStatus) : ''}</div>;

    const changeAttribute = (type, value) => {

        let selectedVariationId = product.databaseId;

        product.variations.forEach(variation => {
            if (variation.attributes[type] === value) {
                selectedVariationId = variation.id;
            }
        })

        setProductId(selectedVariationId);

    }

    const variationSelect = () => {

        return <div className={styles.variationAttributesWrapper}>
            {
                product.attributes.map(attribute => {
                    if (attribute.variation) {


                        return <>
                            <h5>{attribute.title}</h5>
                            <div className={styles.variationAttributesContainer}>
                                {
                                    attribute.options.map(option => {

                                        // check if there is a variation with this attribute & option
                                        let show = false;
                                        product.variations.forEach(variation => {
                                            if (variation.attributes[attribute.id] === option) {
                                                show = true;
                                            }
                                        })

                                        if (!show) {
                                            return null;
                                        }

                                        return <div onClick={() => changeAttribute(attribute.id, option)} className={`${styles.variationAttributesOption} ${productAttributes[attribute.id] === option ? styles.selected : undefined}`}>{option}</div>
                                    })
                                }
                            </div>
                        </>
                    }
                })
            }
        </div>

    }

    const quantityDiscountSection = () => {

        if (quantityDiscounts === null) {
            return <></>;
        }

        return <table style={{ width: '100%', marginTop: 30, marginBottom: 10 }}>
            {
                quantityDiscounts.map(discount => {

                    if (!isNaN(discount.quantityPrice)) {
                        discount.quantityPrice = discount.quantityPrice.toString();
                        discount.quantityPrice = discount.quantityPrice.replace('.', ',');
                    }

                    let split = discount.quantityPrice.split(',');

                    return <tr>
                        <td>Ab {parseInt(discount.minQuantity)} Stück</td>
                        <td style={{ textAlign: 'right' }}>€ {split[0]},{split[1]} / Stück</td>
                    </tr>
                })
            }

        </table>
    }

    return (
        <Layout
        >
            <SEO title={product.title} />
            <div className={`container mobile_reverse`} style={{ paddingTop: 50 }}>
                <div className={`col6`}>

                    <div>
                        <ImageCarousel
                            slides={[
                                getProductImageUrl(sku, '1000x1000'),
                            ]}
                        />
                        <div className={styles.contentSection}>
                            <div className="hideDesktop">
                                {stockSection}
                                {metaSection}
                            </div>

                            <AccordionContainer additionalStyles={{ marginTop: 50 }}>
                                {product.content && <Accordion open={true} headline="Beschreibung">
                                    {parse(product.content)}
                                </Accordion>}
                                <Accordion id={"commentRef"} open={product.content == null} headline={`Bewertungen (${product.comments.length})`}>

                                    <div className={styles.commentForm}>
                                        {
                                            loading && <LoadingSpinner type="wholeContainerTransparent" active={true} />
                                        }
                                        <form onSubmit={handleSubmit(submitRating)}>

                                            <Ratings
                                                rating={rating}
                                                additionalStyles={{ marginBottom: 10 }}
                                                adjustable
                                                error={ratingError}
                                                onClick={(rating) => { setRating(rating) }}
                                            />
                                            <TextInput
                                                type="text"
                                                label="Vorname"
                                                {...register('firstname')}
                                                reset={resettingRatingForm}
                                            />
                                            <TextInput
                                                type="text"
                                                label="Nachname"
                                                {...register('lastname')}
                                                reset={resettingRatingForm}
                                            />
                                            <TextInput
                                                required
                                                type="email"
                                                label="E-Mail"
                                                {...register('email')}
                                                reset={resettingRatingForm}
                                            />
                                            <TextInput
                                                type="text"
                                                label="Kommentar"
                                                {...register('comment')}
                                                reset={resettingRatingForm}
                                            />
                                            <Button type="submit" title="Senden" />
                                        </form>
                                    </div>

                                </Accordion>
                            </AccordionContainer>

                        </div>
                    </div>
                </div>
                <div className={`col1`}>
                </div>
                <div className={`col5`}>
                    <div>
                        <h1
                            style={{ marginBottom: 20 }}
                            className={styles.title}
                        >{product.title}</h1>
                        <Ratings
                            rating={totalRating}
                            additionalStyles={{ marginBottom: 40 }}
                            additionalLabel={`(${product.comments.length})`}
                            onContainerClick={scrollToComments}
                        />
                        <br />

                        <div className={styles.priceContainer}>
                            {
                                salePrice !== '' &&
                                <Price
                                    size="extrasmall"
                                    value={regularPrice}
                                    containerStyle={{ marginTop: 0, marginRight: 10, textDecoration: "line-through", opacity: 0.6 }}
                                    promotion
                                />
                            }

                            <Price
                                size="big"
                                value={price}
                                containerStyle={{ marginTop: 0 }}
                            />
                        </div>

                        <div className="hideMobile">
                            {stockSection}
                        </div>

                        {variationSelect()}

                        {stockStatus === 'instock' &&
                            <div className={`container fluid ${styles.orderContainer}`} style={{ marginTop: 30, marginBottom: 30, padding: 0 }}>
                                <div className="col4"
                                    style={{ alignItems: 'center' }}
                                >
                                    <Counter value={quantity} onChange={(newQuantity) => { setQuantity(newQuantity) }} />
                                </div>
                                <div className="col8">
                                    <AddToCartButton
                                        id={productId}
                                        quantity={quantity}
                                        price={price}
                                        title={productTitle}
                                        detail={productDetail}
                                        additionalData={productAdditionalData}
                                        cb={() => setQuantity(1)}
                                        image={cartImageUrl}
                                        uri={product.uri}
                                    />
                                </div>
                            </div>
                        }

                        {product.hasTextfield && <TextInput multiline onChange={(e) => setTextfieldValue(e.target.value)} label="Text" />}

                        <div className="">
                            {quantityDiscountSection()}
                        </div>

                        <div className="hideMobile">
                            {metaSection}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`container`} style={{ paddingTop: 100, marginBottom: 30 }}>
                <div className={`col12`}>
                    <div>
                        <h3>Ähnliche Produkte</h3>
                        <br />
                        <ProductSlider products={sameProductsList} />
                    </div>
                </div>
            </div>
        </Layout >
    )
}

export default SimpleProductPage;