import React, { useRef, useState } from "react"
import * as styles from "./styles.module.scss"

const ImageCarousel = ({ slides }) => {

    const [current, setCurrent] = useState(0);

    return <div className={styles.container}>
        {slides.map((image, key) => {
            return <div className={`${styles.slide} ${current === key ? styles.active : null}`}>
                <img src={image} />
            </div>
        })}
        {slides.length > 1 &&
            <div className={styles.bulletContainer}>
                {slides.map((image, key) => {
                    return <div 
                    className={`${styles.bullet} ${current === key ? styles.active : null}`} 
                    style={{ backgroundImage: `url(${image})` }}
                    onClick={() => setCurrent(key)}
                    ></div>
                })}
            </div>
        }
    </div>

}

export default ImageCarousel
