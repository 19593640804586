import React, { useEffect, useRef, useState } from "react"
import { Link, graphql } from "gatsby"
// import smoothscroll from 'smoothscroll-polyfill';

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Price from "../../../components/elements/ProductItem/price"
import * as styles from "./styles.module.scss";
import AddToCartButton from "../../../components/elements/AddToCartButton"
import parse from 'html-react-parser';
import Accordion from "../../../components/elements/Accordion/Accordion"
import AccordionContainer from "../../../components/elements/Accordion"
import { useForm } from 'react-hook-form'
import Button from "../../../components/elements/Button"
import Counter from "../../../components/elements/Counter"
import Ratings from "../../../components/elements/Ratings"
import ImageCarousel from "../../../components/elements/ImageCarousel";
import getProductImageUrl from "../../../functions/image";
import TextInput from "../../../components/elements/Inputs/TextInput";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setNotification } from "../../../store/actions";
import { API_BASE } from "../../../spectory-config";
import LoadingSpinner from "../../../components/elements/LoadingSpinner";
import { floatToString } from "../../../functions/money"

const CouponProductPage = ({serverData}) => {

    // smoothscroll.polyfill();

    const { register, handleSubmit } = useForm();
    const { register: registerCoupon, watch } = useForm();
    const commentRef = useRef();


    const product = serverData.product;
    console.log("PRODUCT DATA", product)

    const [resettingRatingForm, setResettingRatingForm] = useState(false);
    const [rating, setRating] = useState(0);
    const [ratingError, setRatingError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [price, setPrice] = useState(product.price);
    const [disabled, setDisabled] = useState(true);

    const [quantity, setQuantity] = useState(1);


    watch((data, { name, type }) => {

        let d = data.couponValue;
        d = d.replace('€', '');

        setPrice(d);

        d = d.replace(',', '.');
        d = parseFloat(d);

        setDisabled(parseFloat(d) < parseFloat(product.price) || isNaN(d));

    })



    const dispatch = useDispatch();

    const resetRatingForm = () => {
        setResettingRatingForm(true);
        setTimeout(() => setResettingRatingForm(false), 20);
    }

    let totalRating = 0;
    let ratingsSum = 0;
    product.comments.forEach(comment => {
        ratingsSum = ratingsSum + parseInt(comment.rating);
    })

    totalRating = ratingsSum / product.comments.length;

    return (
        <Layout
        >
            <SEO title={product.title} />
            <div className={`container mobile_reverse`} style={{ paddingTop: 50, paddingBottom: 50 }}>
                <div className={`col6`}>

                    <div>
                        <ImageCarousel
                            slides={[
                                getProductImageUrl(product.sku, '1000x1000'),
                            ]}
                        />
                        <div className={styles.contentSection}>
                            <div className="hideDesktop">
                                Artikelnummer: {product.sku}<br />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`col1`}>
                </div>
                <div className={`col5`}>
                    <div>
                        <h1
                            style={{ marginBottom: 20 }}
                            className={styles.title}
                        >{product.title}</h1>
                        <br />

                        <div className={styles.priceContainer}>
                            <p>Mindestwert: € {floatToString(parseFloat(product.price))}</p>
                            <TextInput
                                type="price"
                                label="Gutscheinwert"
                                {...registerCoupon('couponValue')}
                                reset={resettingRatingForm}
                            />
                        </div>


                        {/* Add Field for custom notes */}

                        <div className={`container fluid ${styles.orderContainer}`} style={{ marginTop: 30, marginBottom: 30, padding: 0 }}>
                            <div className="col12">
                                <AddToCartButton
                                    id={product.databaseId}
                                    quantity={1}
                                    price={price}
                                    title={product.title}
                                    cb={() => setQuantity(1)}
                                    image={getProductImageUrl(product.sku, '260x260')}
                                    uri={product.uri}
                                    isCoupon={true}
                                    disabled={disabled}
                                />
                            </div>
                        </div>

                        <div className="hideMobile">
                            Artikelnummer: {product.sku}<br />
                        </div>
                    </div>
                </div>
            </div >
        </Layout >
    )
}

export default CouponProductPage