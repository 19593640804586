import React from "react"
import { getProducts } from "../../functions/queries/products"

import SimpleProductPage from "../../templates/shop/single/simpleProduct";
import CouponProductPage from "../../templates/shop/single/couponProduct";
import { API_BASE } from "../../spectory-config";

const ProductPage = ({ serverData }) => {

    console.log("PRODUCT", serverData.product)
    console.log("relatedProducts", serverData.relatedProducts)

    if (serverData.product.productType === 'coupon') {
        return <CouponProductPage serverData={serverData} />
    } else {
        return <SimpleProductPage serverData={serverData} />
    }
}

export default ProductPage

export async function getServerData(context) {

    var axios = require('axios');

    var config = {
        method: 'post',
        url: `https://wp.nimmervoll.net/wp-json/siegfried/v1/shop/product`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: { slug: context.params.slug }
    };

    let response = await axios(config);


    let relatedProducts = await getProducts(context, { perPage: 5, category: response.data.categories[0]?.slug });

    return {
        props: {
            product: response.data,
            relatedProducts: relatedProducts.products
        }
    }
}
