import React, { useRef, useState, useEffect } from "react"
import * as styles from "./styles.module.scss"
import Star from '../../../images/Icons/star.svg';

const Ratings = ({ rating, additionalStyles, adjustable, onClick, additionalLabel, onContainerClick, error }) => {

    let stars = [];
    const [actualRating, setActualRating] = useState(rating);
    const [hasError, setHasError] = useState(false);

    const onHover = (key) => {
        if (adjustable === undefined) return;
        setActualRating(key);
    }

    const handleOnClick = (val) => {
        if (adjustable === undefined) return;
        onClick(val)
        setHasError(false);
    }

    useEffect(() => {
        if (error === true) {
            setHasError(true);
        }
    }, [error])

    Array.from({ length: 5 }).forEach((_, i) => {

        if (i < actualRating) {
            stars.push(<Star
                onMouseEnter={() => { onHover(i + 1) }}
                onMouseLeave={() => { onHover(rating) }}
                onClick={() => handleOnClick(i + 1)}
                className={`${styles.star} ${styles.filled} ${adjustable !== undefined ? styles.clickable : null}`} />);
        } else {
            stars.push(<Star
                onMouseEnter={() => { onHover(i + 1) }}
                onMouseLeave={() => { onHover(rating) }}
                onClick={() => handleOnClick(i + 1)}
                className={`${styles.star} ${adjustable !== undefined ? styles.clickable : null}`} />);
        }

    })


    return (
        <div onClick={onContainerClick} className={`${styles.container} ${onContainerClick !== undefined ? styles.clickable : undefined} ${hasError ? styles.error : undefined}`} style={additionalStyles}>
            {stars}
            {additionalLabel}
        </div>
    );


}

export default Ratings
