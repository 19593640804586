import React from "react"
import * as styles from "./styles.module.scss"
import HeartIcon from "../../../images/Icons/heart.svg"
import { Link } from "@reach/router"
import { withPrefix } from "gatsby-link"
import { strPriceToFloat } from "../../../functions/money"
import { saveToCart } from "../../../functions/cart"
import { useDispatch } from 'react-redux'
import { addToFavourite } from "../../../functions/favourite"

const AddToCartButton = (props) => {

    const dispatch = useDispatch();

    console.log("AddToCartButton", props);

    return <div className={`${styles.buttonContainer}`} style={props.additionalStyle}>
        <div className={`${styles.addToCart} ${props.disabled === true ? styles.disabled : undefined}`} onClick={(e) => {
            e.preventDefault();
            if (props.disabled === true) {
                return false;
            }
            saveToCart({
                id: props.id,
                quantity: props.quantity,
                price: strPriceToFloat(props.price),
                title: props.title,
                detail: props.detail,
                additionalData: props.additionalData,
                image: props.image,
                uri: props.uri,
                isCoupon: props.isCoupon || false
            }, dispatch);

            if (props.cb !== undefined) {
                props.cb();
            }

        }}>In den Warenkorb</div>
        <div className={styles.addToFavourite} onClick={(e) => {
            e.preventDefault();
            addToFavourite({
                id: props.id,
                price: strPriceToFloat(props.price),
                title: props.title,
                detail: props.detail,
                image: props.image,
                uri: props.uri,
            }, dispatch);

            if (props.cbFavourite !== undefined) {
                props.cbFavourite();
            }
        }}>
            <HeartIcon />
        </div>
    </div>

}

export default AddToCartButton
