import React, { useRef, useEffect, useState } from "react"
import * as styles from "../Normal/styles.module.scss"

import { API_BASE, OFFERS_RANDOM_ENDPOINT } from "../../../../spectory-config";
import ProductItem from "../../ProductItem";

import getProductImageUrl from "../../../../functions/image";

const ProductSlider = ({ products }) => {


    console.log("PRODUCTS", products)

    const [titleHeight, setTitleHeight] = useState(null);
    const [detailHeight, setDetailHeight] = useState(null);

    let titleHeightCache = 0;
    let detailHeightCache = 0;

    const getHeights = (title, detail) => {

        if (title > titleHeightCache) {
            titleHeightCache = title;
            setTitleHeight(title)
        }
        if (detail > detailHeightCache) {
            detailHeightCache = detail;
            setDetailHeight(detail);
        }
    }


    let width = products.length * 340;
    width = width - 10


    return (
        <div className={styles.container}>
            <div className={styles.slideWrapper} style={{ width: width }}>
                {
                    products?.map(({ id, sku, title, detail, price, uri }) => {

                        return <ProductItem
                            getHeights={getHeights}
                            image_url={getProductImageUrl(sku, '260x260')}
                            id={id}
                            title={title || ''}
                            detail={detail}
                            price={price}
                            destination={uri}
                            titleHeight={titleHeight}
                            detailHeight={detailHeight}
                        />

                    })
                }
            </div>
        </div>
    );


}

export default ProductSlider
